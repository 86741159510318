import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { useFormStore } from "../../Hooks/store/formStore";

export default function CustomSeparator() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const [isFormComplete, setIsFormComplete] = React.useState(false);
  const { height,dob } = useFormStore();
  const breadcrumbs = [
    {
      key: "1",
      href: "/",
      label: (
        <img
          src="./images/homeIcon.png"
          alt=""
          style={{ width: "23px", display: "block" }}
        />
      ),
      isActive:true
    },
    {
      key: "2",
      href: "/Measurement",
      label: "MEASUREMENTS",
      isActive:dob
    },
    {
      key: "3",
      href: "/result",
      label: "RESULTS",
      isActive:height
    },
  ];

  return (
    <Stack sx={{ alignItems: "center" }}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" color="grey" />}
        aria-label="breadcrumb"
        sx={{
          color: "#a6a6a6",
          fontWeight: "bold",
        }}
      >
        {breadcrumbs.map((breadcrumb, index) =>
          !breadcrumb?.isActive? (
            // Render the active breadcrumb as Typography
            <Typography
              key={breadcrumb.key}
              sx={{
                color: "#a6a6a6",
                fontSize: {
                  xs:14,
                  sm: 16,
                },
                fontWeight: "bold",
                cursor: "not-allowed",
              }}
            >
              {breadcrumb.label}
            </Typography>
          ) : (
            <Typography
              key={breadcrumb.key}
              onClick={(e) => {
                console.log(breadcrumb?.isActive);
                
                breadcrumb?.isActive &&  navigate(breadcrumb.href);
              }}
              sx={{
                // color: !isFormComplete && index !== 0 ? "#a6a6a6" : "#fa6900",
               
                fontWeight: "bold",
                // cursor: !isFormComplete && index !== 0 ? "not-allowed" : "pointer",
                textDecoration: "none",
                cursor:"pointer",
                color: "#fa6900",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
            >
              {breadcrumb.label}
            </Typography>
          )
        )}
      </Breadcrumbs>
    </Stack>
  );
}
