import * as React from "react";
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { useFormStore } from "../Hooks/store/formStore.js";
import HeightImg from "../assets/images/inch-image.webp";
import WeighttImg from "../assets/images/weight-image.webp";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// const userUnit = useFormStore.getState().userUnit;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function calculateAge(dateString) {
  const [day, month, year] = dateString.split("/").map(Number);
  const birthDate = new Date(year, month - 1, day); // Months are 0-based in JavaScript
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();

  // Adjust age if the current month is before the birth month, or if it's the birth month but the current day is before the birth day
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const setMeasurement = useFormStore((state) => state.setMeasurement);
  const setUserUnit = useFormStore((state) => state.setUserUnit);
  const setMaintenanceCalories = useFormStore(
    (state) => state.setMaintenanceCalories
  );
  const setTargetCalories = useFormStore((state) => state.setTargetCalories);
  const navigate = useNavigate();
  const { height, weight } = useFormStore();

  const userUnit = useFormStore.getState().userUnit;
  console.log(userUnit, "---------userUnit");
  const dob = useFormStore.getState().dob;

  React.useEffect(() => {
    if (!dob) navigate("/");
  }, [dob, navigate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setUserUnit({ userUnit: "imperial" });
    } else if (newValue === 1) {
      setUserUnit({ userUnit: "metric" });
    }
  };

  // const setFormData = useFormStore((state) => state.setFormData);
  // Validation schema
  let validationSchema;
  if (userUnit === "imperial") {
    // imperial validation
    validationSchema = Yup.object({
      height: Yup.number()
        .required("This field is required")
        .min(48, "Minimum value is 48 inches"),
      weight: Yup.number()
        .required("This field is required")
        .min(1, "Minimum value is 1 lb"),
    });
  } else {
    // metric validation
    validationSchema = Yup.object({
      height: Yup.number()
        .required("This field is required")
        .min(122, "Minimum value is 122 cm"),
      weight: Yup.number()
        .required("This field is required")
        .min(1, "Minimum value is 1 kg"),
    });
  }

  // const [dynamicHeight,setDynamicHright]=React.useState(height);
  // const [dynamicWeight,setDynamicWeight]=React.useState(weight);

  // React.useEffect(()=>{})

  function inchesToCm(inches) {
    console.log(inches * 2.54);
    return inches * 2.54;
  }

  function cmToInches(cm) {
    console.log(cm * 0.393701);
    return cm * 0.393701;
  }

  // Convert Kilograms to Pounds
  function kgToLb(kg) {
    console.log(kg * 2.20462);
    return kg * 2.20462;
  }

  // Convert Pounds to Kilograms
  function lbToKg(lb) {
    console.log(lb * 0.453592);
    return lb * 0.453592;
  }

  const formik = useFormik({
    initialValues: {
      height: height,
      weight: weight,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission
      let age = calculateAge(dob);
      
      let convertedValues = { ...values };
      if (userUnit === "imperial") {
        // Convert height (inches to cm) and weight (lb to kg)
        convertedValues.height = (parseFloat(values.height) * 2.54).toFixed(2); // inches to cm
        convertedValues.weight = (parseFloat(values.weight) / 2.20462).toFixed(
          2
        ); // lb to kg

        let maintenanceCalories = (10 * convertedValues.weight + 6.25 * convertedValues.height - 5 * age + 5 ) * 1.09;
        let roundDownNumber = Math.floor(maintenanceCalories / 10) * 10;
        setMaintenanceCalories({ maintenanceCalories: roundDownNumber });
        let targetCalories = (roundDownNumber * 0.85).toFixed(0);
        setTargetCalories({
          targetCalories: targetCalories,
        });
      } else if (userUnit === "metric") {
        let maintenanceCalories = (10 * values.weight + 6.25 * values.height - 5 * age + 5 ) * 1.09;
        let roundDownNumber = Math.floor(maintenanceCalories / 10) * 10;
        setMaintenanceCalories({ maintenanceCalories: roundDownNumber });

        let targetCalories = (maintenanceCalories * 0.85).toFixed(0);
        setTargetCalories({
          targetCalories: targetCalories,
        });
      }
      setMeasurement(values);
      navigate("/result");
    },
  });
  const isFormComplete = formik.values.height && formik.values.weight;

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          mx: "-24px",
          boxShadow: "0px 12px 16px 2px rgb(166 166 166 / 20%)",
          "& .MuiTabs-indicator": {
            height: "3px",
            backgroundColor: "#fa6900",
          },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root.Mui-selected": {
              color: "#fa6900",
            },
            "& .MuiTab-root.Mui-selected span": {
              borderColor: "#fa6900",
            },
          }}
        >
          <Tab
            label="Imperial"
            {...a11yProps(0)}
            sx={{
              width: "50%",
              fontSize: "18px",
              textTransform: "capitalize",
              fontWeight: "700",
              color: "#a6a6a6",
              fontFamily: "'Fredoka', sans-serif",
            }}
          />
          <Tab
            label="Metric"
            {...a11yProps(1)}
            sx={{
              width: "50%",
              fontSize: "18px",
              textTransform: "capitalize",
              fontWeight: "700",
              color: "#a6a6a6",
              fontFamily: "'Fredoka', sans-serif",
            }}
          />
        </Tabs>
      </Box>

      <CustomTabPanel value={value} index={0}>
        {/* form data for imperial */}

        <FormControl
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              px: {
                xs: 2.2,
                sm: 3,
              },
              py: 2.3,
              mb: 2.5,
              background: "rgba(250, 105, 2, 0.15)",
              borderRadius: 8,
            }}
            display="flex"
            alignItems="center"
            gap="20px"
          >
            <Box flexShrink="0" sx={{
              height: {
                xs: "75px",
                sm: "90px",
              }, 
              width: {
                xs: "70px",
                sm: "130px",
              }, 
             }}>
              <img
                src={HeightImg}
                alt="image"
                style={{
                  display: "block",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
            </Box>

            <Box flexGrow="1">
              <Typography level="h4" fontWeight={700} sx={{
                  fontSize: {
                    xs: "14px",
                    sm: "18px",
                  } 
                }}>
                Height
              </Typography>
              <TextField
                id="Height"
                variant="standard"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                error={formik.touched.height && Boolean(formik.errors.height)}
                helperText={formik.touched.height && formik.errors.height}
                onKeyPress={(event) => {
                  if (!/[0-9.]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                sx={{
                  width: 100,
                  mt: 2.5,
                  '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #002a4d",
                  }
                }}
              />
              <p style={{ margin: 0, marginTop: "1px", fontSize: "14px" }}>
                inches
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              px: {
                xs: 2.2,
                sm: 3,
              },
              py: 2.3,
              mb: 2.5,
              background: "rgba(250, 105, 2, 0.15)",
              borderRadius: 8,
            }}
            display="flex"
            alignItems="center"
            gap="20px"
          >
            <Box flexShrink="0" sx={{
              height: {
                xs: "70px",
                sm: "90px",
              }, 
              width: {
                xs: "70px",
                sm: "130px",
              }, 
             }}>
              <img
                src={WeighttImg}
                alt="image"
                style={{
                  display: "block",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
            </Box>

            <Box flexGrow="1">
              <Typography level="h4" fontWeight={700} sx={{ 
                fontSize: {
                  xs: "14px",
                  sm: "18px",
                } 
               }}>
                Weight
              </Typography>
              <TextField
                id="Weight"
                variant="standard"
                name="weight"
                value={formik.values.weight}
                onChange={formik.handleChange}
                error={formik.touched.weight && Boolean(formik.errors.weight)}
                helperText={formik.touched.weight && formik.errors.weight}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                sx={{
                  width: 100,
                  mt: 2.5,
                  '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #002a4d",
                  }
                }}
              />
              <p style={{ margin: 0, marginTop: "1px", fontSize: "14px" }}>
                lb
              </p>
            </Box>
          </Box>

          <Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                px: 2.5,
                py: 1,
                mx: "auto",
                width: "100%",
                maxWidth: "225px",
                display: "block",
                background: isFormComplete ? "#fa6900" : "#ffffff",
                color: isFormComplete ? "#ffffff" : "#a6a6a6",
                border: `2px solid ${isFormComplete ? "#fa6900" : "#a6a6a6"}`,
                fontWeight: "700",
                textTransform: "capitalize",
                fontSize: "18px",
                borderRadius: "20px",
                boxShadow: "none",
                "&:hover": {
                  background: "#fa6900",
                  border: "2px solid #fa6900",
                  color: "#ffffff",
                },
              }}
              disabled={!isFormComplete}
            >
              Next
            </Button>
          </Box>
        </FormControl>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={1}>
        {/* form data for metric */}
        <FormControl
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              px: {
                xs: 2.2,
                sm: 3,
              },
              py: 2.3,
              mb: 2.5,
              background: "rgba(250, 105, 2, 0.15)",
              borderRadius: 8,
            }}
            display="flex"
            alignItems="center"
            gap="20px"
          >
            <Box flexShrink="0" sx={{
              height: {
                xs: "75px",
                sm: "90px",
              }, 
              width: {
                xs: "70px",
                sm: "130px",
              }, 
             }}>
              <img
                src={HeightImg}
                alt="image"
                style={{
                  display: "block",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
            </Box>

            <Box flexGrow="1">
              <Typography level="h4" fontWeight={700} sx={{ 
                fontSize: {
                  xs: "14px",
                  sm: "18px",
                } 
               }}>
                Height
              </Typography>
              <TextField
                id="Height"
                variant="standard"
                name="height"
                value={formik.values.height}
                onChange={formik.handleChange}
                error={formik.touched.height && Boolean(formik.errors.height)}
                helperText={formik.touched.height && formik.errors.height}
                sx={{
                  width: 100,
                  mt: 2.5,
                  '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #002a4d",
                  }
                }}
              />
              <p style={{ margin: 0, marginTop: "1px", fontSize: "14px" }}>
                cm
              </p>
            </Box>
          </Box>

          <Box
            sx={{
              px: {
                xs: 2.2,
                sm: 3,
              },
              py: 2.3,
              mb: 2.5,
              background: "rgba(250, 105, 2, 0.15)",
              borderRadius: 8,
            }}
            display="flex"
            alignItems="center"
            gap="20px"
          >
            <Box flexShrink="0" sx={{
              height: {
                xs: "70px",
                sm: "90px",
              }, 
              width: {
                xs: "70px",
                sm: "130px",
              }, 
             }}>
              <img
                src={WeighttImg}
                alt="image"
                style={{
                  display: "block",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              />
            </Box>

            <Box flexGrow="1">
              <Typography level="h4" fontWeight={700} sx={{ 
                fontSize: {
                  xs: "14px",
                  sm: "18px",
                } 
               }}>
                Weight
              </Typography>
              <TextField
                id="Weight"
                variant="standard"
                name="weight"
                value={formik.values.weight}
                onChange={formik.handleChange}
                error={formik.touched.weight && Boolean(formik.errors.weight)}
                helperText={formik.touched.weight && formik.errors.weight}
                sx={{
                  width: 100,
                  mt: 2.5,
                  '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #002a4d",
                  }
                }}
              />
              <p style={{ margin: 0, marginTop: "1px", fontSize: "14px" }}>
                kg
              </p>
            </Box>
          </Box>
          <Box>
            <Button
              type="submit"
              variant="contained"
              sx={{
                px: 2.5,
                py: 1,
                mx: "auto",
                width: "100%",
                maxWidth: "225px",
                display: "block",
                background: isFormComplete ? "#fa6900" : "#ffffff",
                color: isFormComplete ? "#ffffff" : "#a6a6a6",
                border: `2px solid ${isFormComplete ? "#fa6900" : "#a6a6a6"}`,
                fontWeight: "700",
                textTransform: "capitalize",
                fontSize: "18px",
                borderRadius: "20px",
                boxShadow: "none",
                "&:hover": {
                  background: "#fa6900",
                  border: "2px solid #fa6900",
                  color: "#ffffff",
                },
              }}
              disabled={!isFormComplete}
            >
              Next
            </Button>
          </Box>
        </FormControl>
      </CustomTabPanel>
    </Box>
  );
}
