import { create } from "zustand";

export const useFormStore = create((set) => ({
  userUnit: "imperial",
  gender: undefined,
  dob: undefined,
  height: undefined,
  weight: undefined,
  maintenanceCalories: 0,
  targetCalories: 0,
  setUserUnit: ({ userUnit }) => set(() => ({ userUnit: userUnit })),
  setBasicDetails: ({ gender, dob }) => set((state) => ({ gender, dob })),
  setMeasurement: ({ height, weight }) => set(() => ({ height, weight })),
  setMaintenanceCalories: ({ maintenanceCalories }) =>
    set(() => ({ maintenanceCalories: maintenanceCalories })),
  setTargetCalories: ({ targetCalories }) =>
    set(() => ({ targetCalories: targetCalories })),
}));
