import React from "react";
import { FormControlLabel, Radio, Box, Typography } from "@mui/material";
import MaleIcon from "../assets/images/MaleIcon.jsx";

const FormControlLabelWithImage = ({ value, label, ImageSrc, ...rest }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <FormControlLabel
        value={value}
        control={<Radio />}
        label={
          <Box sx={{ width: "100%" }}>
            {/* <img
              src={imageSrc}
              alt={label}
              style={{
                width: "70px",
                height: "70px",
                borderRadius: "999px",
                margin: "auto",
                marginBottom: "6px",
                objectFit: "cover",
                border: "3px solid transparent",
              }}
            /> */}
            <Box sx={{width:{
                xs: "50px",
                sm: "70px",
              },
            }}>
              <ImageSrc/>
            </Box>
            <Typography fontWeight={500} fontSize={14} marginTop={0.5}>{label}</Typography>
          </Box>
        }
        {...rest}
      />
    </Box>
  );
};

export default FormControlLabelWithImage;
