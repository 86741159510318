import {
  Box,
  Button,
  FormControl,
  MenuItem,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CustomSeparator from "../component/Header/CustomSeparator.jsx";
import FormControlLabelWithImage from "../component/FormControlLabelWithImage.jsx";
import { useFormik } from "formik";
import { useFormStore } from "../Hooks/store/formStore.js";
import { useNavigate } from "react-router-dom";

import FemaleUserImg from "../assets/images/female-img.svg";
import MaleUserImg from "../assets/images/male-img.svg";

import * as Yup from "yup";
import dayjs from "dayjs";
import FemaleActiveIcon from "../assets/images/FemaleActiveIcon.jsx";
import FemaleIcon from "../assets/images/FemaleIcon.jsx";
import MaleActiveIcon from "../assets/images/MaleActiveIcon.jsx";
import MaleIcon from "../assets/images/MaleIcon.jsx";


const selectInputSx = {
  mt: "22px",
  width: {
    xs: "120px",
    sm: "33%",
  },
  borderRadius: "0px",
  '& .MuiSelect-select:focus': {
    borderRadius: "0px",
  },
  "& .MuiOutlinedInput-input": {
    textAlign: "justify",
    color: "#8b8989",
    // color: "orange",
    fontSize: {
      xs: "14px",
      sm: "18px"
    },
    fontWeight: "500",
    p: 0,
    pb:0.5,
    height: "unset",
    minHeight: "unset",
    borderBottom: "2px solid",
    borderRadius: 0,
    '&:focus':{
      borderColor: "#000",
    }
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent",
  },
  "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
    p: 0,
  },

  "& .MuiSelect-select": {
    minHeight: "unset !important",
  },
  "& fieldset": { border: "none" },
  "& > *": {
    paddingRight: "0 !important",
  },
};

const generateMonths = () => {
  return Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month, index) => (
      <MenuItem key={month} value={index + 1}>
        {dayjs()
          .month(month - 1)
          .format("MMM")}
      </MenuItem>
    )
  );
};

// Date validation function
function isValidDate(month, day, year) {
  const dateObject = new Date(year, month - 1, day); // months are 0-based in JS
  return (
    dateObject.getFullYear() === parseInt(year, 10) &&
    dateObject.getMonth() === month - 1 &&
    dateObject.getDate() === parseInt(day, 10)
  );
}

function Home() {
  // const [value, setValue] = React.useState("");
  const setBasicDetails = useFormStore((state) => state.setBasicDetails);
  const { gender:genderStore,dob } = useFormStore();
  const dateOfBirth=dob?String(dob).split("/"): [0,0,0];
  console.log(dateOfBirth);
  
  const [gender, setGender] = React.useState(genderStore?genderStore:null);
  console.log(gender);
  
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();

  const validationSchema = Yup.object({
    month: Yup.number()
      .min(1, "Month must be between 1 and 12")
      .max(12, "Month must be between 1 and 12")
      .required("Month is required"),
    day: Yup.number()
      .typeError("Day must be a number")
      .required("Day is required")
      .min(0, "Day must be greater than or equal to 0")
      .max(31, "Day must be less than or equal to 31"),
    year: Yup.number()
      .typeError("Year must be a number")
      .required("Year is required")
      .min(1900, "Year must be greater than or equal to 1900")
      .max(currentYear, `Year must be less than or equal to ${currentYear}`),
  });

  const [formFill, setFormFill] = useState();

  const formik = useFormik({
    initialValues: {
      month: dateOfBirth[1]!==0?dateOfBirth[1]:"month",
      day: dateOfBirth[0]!==0?dateOfBirth[0]:"",
      year: dateOfBirth[2]!==0?dateOfBirth[2]:"",
    },
    validationSchema,
    onSubmit: (values) => {
      const { day, month, year } = values;

      if (!isValidDate(month, day, year)) {
        formik.setErrors({
          day: "Invalid date",
        });
        return;
      }

      setFormFill(values);
      // Handle form submission
      const dob = `${values.day}/${values.month}/${values.year}`;
      isValidDate(dob);
      setBasicDetails({ gender, dob });
      navigate("/Measurement");
    },
  });
  const isFormComplete =
    formik.values.month && formik.values.day && formik.values.year;
  return (
    <>
      <Box
        sx={{
          pb: 2.5,
          px: 3,
          height: "100%",
          mx: "auto",
          backgroundColor: "white",
        }}
      >
        {/* custom separator - home - MEASUREMENTS - RESULTS */}
        <Box
          sx={{
            pt: 3,
            pb: 2.5,
          }}
        >
          <CustomSeparator />
        </Box>

        {/* home page content */}
        <Box>
          <FormControl
            sx={{ width: "100%" }}
            component="form"
            onSubmit={formik.handleSubmit}
          >
            <Box sx={{
              pb:1
            }}>
              <Typography
                level="h4"
                fontWeight="700"
                sx={{ mb: 1.5,
                  mt:{
                    xs: 2,
                    sm: 2.5,
                  }, 
                  fontSize:{
                    xs: 14,
                    sm: 20,
                  }
                 }}
              >
                Your sex
              </Typography>

              <RadioGroup
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  gap: {
                    xs: "60px",
                    sm: "100px",
                  },
                  textAlign: "center",
                }}
              >
                <FormControlLabelWithImage
                  value="female"
                  label="Female"
                  ImageSrc={gender==="female"?FemaleActiveIcon:FemaleIcon}
                  sx={{
                    m: 0,
                    justifyContent: "center",
                    width: "100%",
                    "& .MuiRadio-colorPrimary": {
                      display: "none",
                    },
                    "& .Mui-checked + .MuiFormControlLabel-label .MuiTypography-body1": {
                      color: "#fa6900",
                    },
                  }}
                />
                <FormControlLabelWithImage
                  value="male"
                  label="Male"
                  ImageSrc={gender==="male"?MaleActiveIcon:MaleIcon}
                  sx={{
                    m: 0,
                    justifyContent: "center",
                    width: "100%",
                    "& .MuiRadio-colorPrimary": {
                      display: "none",
                    },
                    "& .Mui-checked + .MuiFormControlLabel-label .MuiTypography-body1": {
                      color: "#fa6900",
                    },
                  }}
                />
              </RadioGroup>
            </Box>

            {/* your birthday */}
            <Box sx={{ py: 2 }}>
              <Typography
                level="h4"
                fontSize={20}
                fontWeight="700"
                sx={{ mb: 1.5,
                  mt:{
                    xs: 2,
                    sm: 2.5,
                  }, 
                  fontSize:{
                    xs: 14,
                    sm: 20,
                  }
                 }}
              >
                Your birthday
              </Typography>
              <Box display="flex" justifyContent="center" gap="16px" alignItems="flex-end">
                {/* <TextField
                  id="month"
                  label="month"
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  variant="standard"
                  error={formik.touched?.month && Boolean(formik.errors?.month)}
                  helperText={formik.touched?.month && formik.errors?.month}
                  sx={{
                    fontWeight: "700",
                    width: "120px",
                    "& .MuiInputLabel-formControl": {
                      color: "#8b8989",
                      textAlign: "center",
                    },
                    "& label": {
                      fontWeight: "500",
                      fontSize: "18px",
                    },
                  }}
                /> */}
                <Select
                  inputProps={{ IconComponent: () => null }}
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  sx={selectInputSx}
                  error={formik.touched?.month && Boolean(formik.errors?.month)}
                  helperText={formik.touched?.month && formik.errors?.month}
                >
                  <MenuItem
                    disabled
                    selected
                    value={"month"}
                    sx={{ color: "red" }}
                  >
                    month
                  </MenuItem>
                  {generateMonths()}
                </Select>
                <TextField
                  id="day"
                  label="day"
                  name="day"
                  value={formik.values.day}
                  onChange={formik.handleChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  variant="standard"
                  error={formik.touched.day && Boolean(formik.errors.day)}
                  helperText={formik.touched.day && formik.errors.day}
                  sx={{
                    width: {
                      xs: "120px",
                      sm: "33%",
                    },
                    "& .MuiInputLabel-formControl": {
                      color: "#8b8989",
                      textAlign: "center",
                    },
                    '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #8b8989",
                    },
                    "& label": {
                      fontWeight: "500",
                      fontSize: {
                        xs: "14px",
                        sm: "18px"
                      },
                    },
                  }}
                />
                <TextField
                  id="year"
                  label="year"
                  name="year"
                  value={formik.values.year}
                  onChange={formik.handleChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  variant="standard"
                  error={formik.touched.year && Boolean(formik.errors.year)}
                  helperText={formik.touched.year && formik.errors.year}
                  sx={{
                    width: {
                      xs: "120px",
                      sm: "33%",
                    },
                    "& .MuiInputLabel-formControl": {
                      color: "#8b8989",
                      textAlign: "center",
                    },
                    '.MuiInputBase-formControl:before':{
                      borderBottom: "2px solid #8b8989",
                    },
                    "& label": {
                      fontWeight: "500",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                        pb: "1px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "center",
                mt: 3.25,
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  px: 2.5,
                  py: 1,
                  mx: "auto",
                  width: "100%",
                  maxWidth: "225px",
                  display: "block",
                  background: isFormComplete ? "#fa6900" : "#ffffff",
                  color: isFormComplete ? "#ffffff" : "#a6a6a6",
                  // color: "#a6a6a6",
                  border: `2px solid ${isFormComplete ? "#fa6900" : "#a6a6a6"}`,
                  fontWeight: "700",
                  textTransform: "capitalize",
                  fontSize: "18px",
                  borderRadius: "20px",
                  boxShadow: "none",
                  "&:hover": {
                    background: "#fa6900",
                    border: "2px solid #fa6900",
                    color: "#ffffff",
                  },
                }}
                disabled={!isFormComplete}
              >
                Next
              </Button>
            </Box>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}

export default Home;
