import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./page/Home";
import Layout from "./component/Layout";
import Measurement from "./page/Measurement";
import Result from "./page/Result";

const routes = createBrowserRouter([
  {
    Component: Layout,
    children: [
      {
        path: "/",
        Component: Home,
      },
      {
        path: "/Measurement",
        Component: Measurement,
      },
      {
        path: "/Result",
        Component: Result,
      },
    ],
  },
]);
export default function Routes() {
  return (
    <>
      <RouterProvider router={routes} fallbackElement="loading" />
    </>
  );
}
