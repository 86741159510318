import React from "react";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

function Layout() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "550px",
          mx: "auto",
          minHeight: "100vh",
          background: "#fff",
        }}
      >
        <Header />
        <Outlet />
      </Box>
    </>
  );
}

export default Layout;
