import * as React from "react";
import { Box, Link } from "@mui/material";
// import MenuIcon from "../../assets/images/menu-icon.png";

function Header() {
  return (
    <Box
      sx={{
        pt: 1.8,
        px: 1.5,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Link href="/">
          <img
            src="./images/favicon.svg"
            alt=""
            srcSet=""
            style={{ width: "38px" }}
          />
        </Link>
        <Link
          href="/"
          style={{
            textDecoration: "none",
            color: "black",
            cursor: "pointer",
            fontSize: "18.50px",
            lineHeight: "1.5",
          }}
        >
          Calorie Calculator
        </Link>
      </Box>
      {/* <Link href="/">
        <img
          src={MenuIcon}
          style={{
            display: "block",
            width: "35px",
            height: "28px",
          }}
          alt="image"
        />
      </Link> */}
    </Box>
  );
}
export default Header;
