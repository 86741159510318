import React from "react";
import CustomSeparator from "../component/Header/CustomSeparator";
import { Box } from "@mui/material";
import CustomTabPanel from "../component/CustomTabPanel";

function Measurement() {
  return (
    <>
      <Box
        sx={{
          pb: 2.5,
          px: 3,
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {/* custom separator - home - MEASUREMENTS - RESULTS */}
        <Box
          sx={{
            py: 2.5,
          }}
        >
          <CustomSeparator />
        </Box>

        {/* tab pannel */}
        <Box>
          <CustomTabPanel />
        </Box>
      </Box>
    </>
  );
}

export default Measurement;
